import React, { useState } from "react";
import Observer from "@researchgate/react-intersection-observer";
import anime from "animejs";
import "./Stats.scss";

let stats = [
  {
    id: "years-experience",
    number: "11",
    title: "Years Experience"
  },
  {
    id: "plumbing-projects",
    number: "250+",
    title: "Plumbing Projects"
  },
  {
    id: "electical-projects",
    number: "200+",
    title: "Electrical Projects"
  },
  {
    id: "teams",
    number: "15",
    title: "Teams"
  }
];

function Stats() {
  const [hasAnimated, setHasAnimated] = useState(false);
  function animateStats() {
    stats.forEach(stat => {
      anime({
        targets: "#" + stat.id,
        textContent: [0, stat.number],
        round: 1,
        easing: "linear",
        duration: 1200
      });
    });
    setHasAnimated(true);
  }

  function handleIntersection({ isIntersecting }) {
    if (isIntersecting) {
      animateStats();
    }
  }

  let statCols = stats.map((stat, index) => (
    <div className="column is-one-quarter stat" key={stat.id}>
      <h4 id={stat.id}>{stat.number}</h4>
      <hr />
      <h6>{stat.title}</h6>
    </div>
  ));

  let observerOptions = {
    onChange: handleIntersection,
    threshold: 0.3,
    disabled: hasAnimated
  };

  return (
    <Observer {...observerOptions}>
      <div className="stats">
        <div className="container">
          <div className="columns">{statCols}</div>
        </div>
      </div>
    </Observer>
  );
}

export default Stats;
