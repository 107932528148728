export default [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "About",
    link: "/about"
  },
  {
    name: "Services",
    link: "/services"
  },
  {
    name: "Blog",
    link: "/blog"
  },
  {
    name: "Contact",
    link: "/contact"
  }
];
