import React from "react";
import "./GalleryGrid.scss";

function GalleryGrid({ elementRef, projects, toggleModal }) {
  let gallery = projects.map(project => {
    let filters = project.filters.map(filter => filter.slug);

    return (
      <figure
        className="gallery-item"
        data-groups={'["' + filters.join('", "') + '"]'}
        key={project.id}
        onClick={() =>
          toggleModal({ src: project.thumbnail, alt: project.title.rendered })
        }
      >
        <div className="aspect aspect--16x9">
          <div className="aspect--inner">
            <img src={project.thumbnail} alt={project.id} />
          </div>
        </div>
      </figure>
    );
  });

  return (
    <div ref={elementRef} className="gallery-grid">
      {gallery}
      <div className="sizer"></div>
    </div>
  );
}

export default GalleryGrid;
