import React from "react";
import { Link } from "react-router-dom";
import services from "services/api/services";
import "./Services.scss";

function Services() {
  let serviceCols = services.map(service => (
    <div className="column is-one-third service" key={service.title}>
      <figure className="image">
        <img src={service.icon} alt={service.title} />
      </figure>
      <h3>{service.title}</h3>
      <hr />
      <p>{service.description}</p>
    </div>
  ));

  return (
    <div className="services section">
      <div className="container">
        <div className="header">
          <h4 className="section-title centered">Our Services</h4>
          <h2>Quality Service is Our Guarantee</h2>
          <p>
            We pride ourselves in being professional, reliable and competent in
            all aspects of the work we perform and guarantee the quality of our
            services from start to finish. All of our clients receive a 30 day
            workmanship guarantee.
          </p>
        </div>
        <div className="columns is-multiline">{serviceCols}</div>
        <Link to="/services" className="button btn-ag">
          View All Services
        </Link>
      </div>
    </div>
  );
}

export default Services;
