import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Obfuscate from "react-obfuscate";
import socials from "services/api/socials";
import "./Subheader.scss";

function Subheader() {
  let socialIcons = socials.map(
    social =>
      social.link && (
        <li key={social.name}>
          <a href={social.link} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={social.icon} />
          </a>
        </li>
      ),
  );

  return (
    <div className="subheader">
      <div className="container">
        <ul className="contact">
          <li>
            <FontAwesomeIcon icon={faPhone} />{" "}
            <Obfuscate tel={process.env.REACT_APP_TELEPHONE} />
          </li>
          <li>
            <FontAwesomeIcon icon={faEnvelope} />{" "}
            <Obfuscate email="info@agplumbing.co.za" />
          </li>
        </ul>
        <ul className="social">{socialIcons}</ul>
      </div>
    </div>
  );
}

export default Subheader;
