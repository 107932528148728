import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import Axios from "axios";
import sanitizeHtml from "sanitize-html";
import moment from "moment";
import "./BlogPost.scss";

function BlogPost() {
  const [article, setArticle] = useState(undefined);
  let { slug } = useParams();
  let apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (err) {
      window.scrollTo(0, 0);
    }
    Axios.get(apiUrl + "wp/v2/posts?slug=" + slug).then(response =>
      setArticle(response.data[0]),
    );
  }, [apiUrl, slug]);

  return (
    <div id="blog-post">
      {article !== undefined && (
        <>
          <div
            className="header"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)), url(${article.thumbnail})`,
              backgroundAttachment: "fixed",
              backgroundPosition: "bottom",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <h1
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(article.title.rendered),
                }}
              />
              <div className="meta">
                <FontAwesomeIcon icon={faCalendarAlt} />
                {moment(article.date).format("MMM D, YYYY")}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns">
              <div
                className="column is-two-third content"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(article.content.rendered, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                      "h1",
                      "h2",
                    ]),
                  }),
                }}
              ></div>
              <div className="column is-one-third"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BlogPost;
