import React from "react";
import "./AboutQuote.scss";

function AboutQuote() {
  return (
    <div id="about-quote" className="section">
      <div className="container">
        <h5>
          "We pride ourselves in being professional, reliable and competent in
          all aspects of the work we perform."
        </h5>
      </div>
    </div>
  );
}

export default AboutQuote;
