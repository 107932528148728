import React, { useEffect, createRef, useState } from "react";
import Axios from "axios";
import Shuffle from "shufflejs";
import { GalleryContentLoader } from "components/ContentLoader";
import GalleryFilter from "../GalleryFilter";
import GalleryGrid from "../GalleryGrid";
import GalleryModal from "../GalleryModal";

const filterCategories = array => {
  let unique = {};
  let filters = [];

  array.forEach(project => {
    project.filters.forEach(filter => {
      if (unique[filter.id]) return;
      unique[filter.id] = true;
      filters.push(filter);
    });
  });

  return filters;
};

function Gallery() {
  const [filters, setFilters] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isCompleted, setCompleted] = useState(false);
  const [modal, setModal] = useState({ show: false, imgData: null });
  const apiUrl = process.env.REACT_APP_API_URL;

  const element = createRef();
  let shuffle = createRef();

  useEffect(() => {
    if (!isCompleted) {
      Axios.get(apiUrl + "wp/v2/projects?per_page=12").then(response => {
        setCompleted(true);
        setFilters(filterCategories(response.data));
        setProjects(response.data);
        setLoading(false);
      });
    } else {
      shuffle.current = new Shuffle(element.current, {
        itemSelector: ".gallery-item",
        sizer: ".sizer",
      });
    }
  }, [apiUrl, element, shuffle, isCompleted]);

  function handleFilter(filter) {
    if (filter === "all") filter = Shuffle.ALL_ITEMS;
    shuffle.current.filter(filter);
  }

  function handleToggle(img) {
    setModal({ show: true, src: img.src, alt: img.alt });
  }

  return (
    <>
      {isLoading ? (
        <GalleryContentLoader rows={3} />
      ) : (
        <GalleryFilter filters={filters} handler={handleFilter} />
      )}
      <GalleryGrid
        elementRef={element}
        projects={projects}
        toggleModal={imgData => handleToggle(imgData)}
      />
      <GalleryModal
        show={modal.show}
        src={modal.src}
        alt={modal.alt}
        onClose={() => setModal({ show: false, imgData: null })}
      />
    </>
  );
}

export default Gallery;
