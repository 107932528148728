import React, { useState } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faUser,
  faEnvelope,
  faMapMarkerAlt,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendarAlt,
  faCircle,
  faDotCircle,
} from "@fortawesome/free-regular-svg-icons";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import "./BookingForm.scss";

function BookingForm() {
  const [requestor, setRequestor] = useState("owner");
  const [date, setDate] = useState("");
  const [submitBtnText, setSubmitBtnText] = useState("Request");
  const { register, handleSubmit, reset } = useForm();
  const apiUrl = process.env.REACT_APP_API_URL;
  const formId = process.env.REACT_APP_BOOKING_FORM_ID;

  function onSubmit(data) {
    setSubmitBtnText("Sending Request...");
    const url = `${apiUrl}contact-form-7/v1/contact-forms/${formId}/feedback`;
    const formData = new FormData();

    formData.append("ag-booking-name", data.name);
    formData.append("ag-booking-telephone", data.telephone);
    formData.append("ag-booking-email", data.email);
    formData.append("ag-booking-address", data.address);
    formData.append("ag-booking-requestor", data.requestor);
    formData.append("ag-booking-date", date);
    formData.append("ag-booking-message", data.message);

    Axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
      if (res.data.status === "mail_sent") {
        reset();
        setSubmitBtnText("Request Sent!");
      }
      setTimeout(() => setSubmitBtnText("Request"), 2500);
    });
  }

  function handleChange(e) {
    setRequestor(e.target.value);
  }

  return (
    <form className="form booking-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="columns is-multiline">
        <div className="column is-half">
          <div className="input">
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              ref={register({ required: true })}
            />
            <FontAwesomeIcon icon={faUser} />
          </div>
        </div>
        <div className="column is-half">
          <div className="input">
            <input
              type="tel"
              name="telephone"
              placeholder="Phone Number"
              ref={register({ required: true })}
            />
            <FontAwesomeIcon icon={faPhone} />
          </div>
        </div>
        <div className="column is-full">
          <div className="input">
            <input
              type="email"
              name="email"
              placeholder="Email"
              ref={register({ required: true, type: "email" })}
            />
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        </div>
        <div className="column is-full">
          <div className="input">
            <input
              type="text"
              name="address"
              placeholder="Address"
              ref={register({ required: true })}
            />
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
        </div>
        <div className="column is-half">
          <h6 className="radio-title">Requesting Service</h6>
          <label className="radio-group">
            <input
              type="radio"
              name="requestor"
              onChange={handleChange}
              value="owner"
              ref={register({ required: true })}
              checked={requestor === "owner"}
            />
            <FontAwesomeIcon
              icon={requestor === "owner" ? faDotCircle : faCircle}
            />
            Owner
          </label>
          <label className="radio-group">
            <input
              type="radio"
              name="requestor"
              onChange={handleChange}
              value="tenant"
              ref={register({ required: true })}
              checked={requestor === "tenant"}
            />
            <FontAwesomeIcon
              icon={requestor === "tenant" ? faDotCircle : faCircle}
            />
            Tenant
          </label>
          <label className="radio-group">
            <input
              type="radio"
              name="requestor"
              onChange={handleChange}
              value="body-corporate"
              ref={register({ required: true })}
              checked={requestor === "body-corporate"}
            />
            <FontAwesomeIcon
              icon={requestor === "body-corporate" ? faDotCircle : faCircle}
            />
            Body Corporate
          </label>
        </div>
        <div className="column is-half">
          <div className="input">
            <Flatpickr
              name="date"
              placeholder="Date"
              onChange={(_, str) => setDate(str)}
            />
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
        </div>
        <div className="column is-full">
          <div className="input">
            <textarea
              name="message"
              rows="12"
              placeholder="Message"
              ref={register({ required: true })}
            ></textarea>
            <FontAwesomeIcon icon={faPen} />
          </div>
        </div>
        <div className="column is-full">
          <button type="submit" className="button btn-ag">
            {submitBtnText}
          </button>
        </div>
      </div>
    </form>
  );
}

export default BookingForm;
