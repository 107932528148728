import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Obfuscate from "react-obfuscate";
import socials from "services/api/socials";
import "./FooterInfo.scss";

function FooterInfo() {
  let socialIcons = socials.map(
    social =>
      social.link && (
        <li key={social.name}>
          <a href={social.link} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={social.icon} />
          </a>
        </li>
      ),
  );

  return (
    <div className="footer-info">
      <div className="columns">
        <div className="column is-one-third">
          <figure className="image">
            <img src="/img/logo.png" alt="AG Plumbing & Electrical" />
          </figure>
        </div>
        <div className="column is-one-third">
          <p>
            At AG Plumbing and Electrical, we pride ourselves in being
            professional, reliable and competent in all aspects of the work we
            perform and guarantee the quality of our services from start to
            finish.
          </p>
          <p>
            Our success depends on our client's satisfaction with our work, that
            is why we strive to continuously improve the quality of our work and
            delivery of our services.
          </p>
        </div>
        <div className="column is-one-third">
          <ul className="contact">
            <li>
              <FontAwesomeIcon icon={faPhone} />{" "}
              <Obfuscate tel={process.env.REACT_APP_TELEPHONE} />
            </li>
            <li>
              <FontAwesomeIcon icon={faPhone} /> After Hours:{" "}
              <Obfuscate tel="082 052 5323" />
            </li>
            <li>
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <Obfuscate email="info@agplumbing.co.za" />
            </li>
            <li>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> 10 Paul Smit Road,
              Ravenswood, Boksburg
            </li>
          </ul>
          <ul className="social">{socialIcons}</ul>
        </div>
      </div>
    </div>
  );
}

export default FooterInfo;
