import React from "react";
import Gallery from "./components/Gallery";
import "./Projects.scss";

function Projects() {
  return (
    <div className="projects section">
      <div className="container">
        <div className="header">
          <h4 className="section-title centered">Our Projects</h4>
          <h2>Some of Our Recent Work</h2>
          <p>
            AG plumbing and Electrical attend to projects of varying sizes for
            both residential and commercial clients. We continually strive to
            provide excellent work of the highest quality to all clients. Below
            are just some of our recent projects.
          </p>
        </div>
        <Gallery />
      </div>
    </div>
  );
}

export default Projects;
