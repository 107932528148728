import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import WhatsAppButton from "components/WhatsAppButton";

import Header from "components/Header";
import Footer from "components/Footer";

import Home from "scenes/Home";
import About from "scenes/About";
import Services from "scenes/Services";
import Blog from "scenes/Blog";
import BlogPost from "scenes/BlogPost";
import Contact from "scenes/Contact";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <WhatsAppButton />
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route exact path="/blog" component={Blog} />
          <Route path="/blog/:slug" component={BlogPost} />
          <Route path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
