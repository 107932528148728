import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import menuItems from "services/api/menu";
import "./Menu.scss";

function Menu() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const history = useHistory();

  function handleClick(link) {
    setShowMobileMenu(false);
    history.push(link);
  }

  let menuLinks = menuItems.map((item) => (
    <Link className="navbar-item" to={item.link} key={item.link}>
      {item.name}
    </Link>
  ));

  let mobileMenuLinks = menuItems.map((item) => (
    <button key={item.name} onClick={() => handleClick(item.link)}>
      {item.name}
    </button>
  ));

  return (
    <div className="menu navbar">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src="/img/logo.png" alt="AG Plumbing & Electrical" />
          </Link>
          <button
            className="navbar-burger burger"
            aria-label="menu"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <nav className="navbar-menu">
          <div className="navbar-end">{menuLinks}</div>
        </nav>
      </div>
      {showMobileMenu && <div className="mobile-menu">{mobileMenuLinks}</div>}
    </div>
  );
}

export default Menu;
