import React from "react";

function GalleryModal({ show, src, alt, onClose }) {
  return (
    <div className={`modal ${show ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <p className="image">
          <img src={src} alt={alt} />
        </p>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    </div>
  );
}

export default GalleryModal;
