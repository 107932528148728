import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

export default [
  {
    name: "Facebook",
    link: process.env.REACT_APP_FACEBOOK_URL,
    icon: faFacebookF,
  },
  {
    name: "Twitter",
    link: process.env.REACT_APP_TWITTER_URL,
    icon: faTwitter,
  },
  {
    name: "Instagram",
    link: process.env.REACT_APP_INSTAGRAM_URL,
    icon: faInstagram,
  },
  {
    name: "YouTube",
    link: process.env.REACT_APP_YOUTUBE_URL,
    icon: faYoutube,
  },
  {
    name: "WhatsApp",
    link: `https://wa.me/${
      process.env.REACT_APP_WHATSAPP_NUMBER
    }?text=${encodeURI(process.env.REACT_APP_DEFAULT_WHATSAPP_MESSAGE)}`,
    icon: faWhatsapp,
  },
];
