import React from "react";
import "./Clients.scss";

function Clients() {
  return (
    <div id="clients" className="section">
      <div className="container">
        <ul>
          <li>
            <figure className="image">
              <img src="/img/clients/remax.png" alt="Remax South Africa" />
            </figure>
          </li>
          <li>
            <figure className="image">
              <img src="/img/clients/kfc.png" alt="KFC" />
            </figure>
          </li>
          <li>
            <figure className="image">
              <img src="/img/clients/discovery.png" alt="Discovery Health" />
            </figure>
          </li>
          <li>
            <figure className="image">
              <img src="/img/clients/spur.png" alt="Spur" />
            </figure>
          </li>
          <li>
            <figure className="image">
              <img src="/img/clients/edcon.png" alt="Edcon" />
            </figure>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Clients;
