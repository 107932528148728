import React, { Fragment } from "react";
import ContentLoader from "react-content-loader";

export default ({ viewBox, children }) => (
  <ContentLoader viewBox={viewBox}>{children}</ContentLoader>
);

const blogPost = () => (
  <Fragment>
    <rect x="0" y="10" rx="5" ry="5" width="376" height="220" />
    <rect x="400" y="20" rx="10" ry="10" width="480" height="32" />
    <rect x="400" y="60" rx="5" ry="5" width="70" height="10" />
    <rect x="400" y="100" rx="3" ry="3" width="750" height="6" />
    <rect x="400" y="120" rx="3" ry="3" width="750" height="6" />
    <rect x="400" y="140" rx="3" ry="3" width="540" height="6" />
    <rect x="400" y="180" rx="3" ry="3" width="60" height="6" />
  </Fragment>
);

const galleryFilter = () => (
  <ContentLoader viewBox="0 0 1152 60">
    <rect x="374" y="30" rx="3" ry="3" width="30" height="6" />
    <rect x="420" y="30" rx="3" ry="3" width="75" height="6" />
    <rect x="515" y="30" rx="3" ry="3" width="72" height="6" />
    <rect x="610" y="30" rx="3" ry="3" width="100" height="6" />
    <rect x="730" y="30" rx="3" ry="3" width="45" height="6" />
  </ContentLoader>
);

const galleryGridRow = () => (
  <Fragment>
    <rect x="5" y="4" width="375" height="212" />
    <rect x="390" y="4" width="375" height="212" />
    <rect x="772" y="4" width="375" height="212" />
  </Fragment>
);

const recentPost = i => (
  <Fragment key={i}>
    <rect x={390 * i} y="0" width="368" height="230" />
    <rect x={390 * i} y="250" rx="7" ry="7" width="80" height="14" />
    <rect x={390 * i} y="280" rx="8" ry="8" width="360" height="16" />
    <rect x={390 * i} y="305" rx="8" ry="8" width="160" height="16" />
    <rect x={390 * i} y="345" rx="7" ry="7" width="80" height="14" />
  </Fragment>
);

export const BlogContentLoader = ({ rows = 1 }) => {
  return [...Array(rows)].map((e, i) => (
    <ContentLoader key={i} viewBox="0 0 1174 284">
      {blogPost()}
    </ContentLoader>
  ));
};

export const GalleryContentLoader = ({ rows = 1 }) => {
  let gridRows = [...Array(rows)].map((e, i) => (
    <ContentLoader key={i} viewBox="0 0 1152 220">
      {galleryGridRow()}
    </ContentLoader>
  ));

  return (
    <>
      {galleryFilter()}
      {gridRows}
    </>
  );
};

export const RecentPostContentLoader = ({ cols = 3, rows = 1 }) => {
  let block = [...Array(cols)].map((e, i) => {
    return recentPost(i);
  });

  return [...Array(rows)].map((e, i) => (
    <ContentLoader key={i} viewBox="0 0 1152 428">
      {block}
    </ContentLoader>
  ));
};
