import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BlogContentLoader } from "components/ContentLoader";
import Axios from "axios";
import moment from "moment";
import sanitizeHtml from "sanitize-html";
import "./Blog.scss";

function Blog() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  let apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    Axios.get(apiUrl + "wp/v2/posts").then(response => {
      setArticles(response.data);
      setLoading(false);
    });
  }, [apiUrl]);

  let posts = articles.map(article => (
    <span key={article.id}>
      <div className="post columns">
        <div className="column is-one-third">
          <Link role="figure" to={`/blog/${article.slug}`} className="image">
            <img src={article.thumbnail} alt={article.title.rendered} />
          </Link>
        </div>
        <div className="column is-two-thirds">
          <Link to={`/blog/${article.slug}`} className="title">
            <h3
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(article.title.rendered),
              }}
            />
          </Link>
          <p className="date">{moment(article.date).format("MMM D, YYYY")}</p>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(article.excerpt.rendered),
            }}
          ></span>
          <Link to={`/blog/${article.slug}`} className="more-link">
            Read More
          </Link>
        </div>
      </div>
      <hr />
    </span>
  ));

  return (
    <div id="blog">
      <div className="header">
        <div className="container">
          <h1>Blog</h1>
          <p>Some of our latest news.</p>
        </div>
      </div>
      <div className="container section">
        {isLoading ? <BlogContentLoader rows={3} /> : posts}
      </div>
    </div>
  );
}

export default Blog;
