import React, { useState } from "react";
import "./GalleryFilter.scss";

function GalleryFilter({ filters, handler }) {
  const [active, setActive] = useState("all");

  function handleClick(filter) {
    handler(filter);
    setActive(filter);
  }

  let filterButtons = filters.map(filter => (
    <li key={filter.id}>
      <button
        className={active === filter.slug ? "active" : undefined}
        onClick={() => handleClick(filter.slug)}
      >
        {filter.name}
      </button>
    </li>
  ));

  return (
    <ul className="gallery-filter">
      <li>
        <button
          className={active === "all" ? "active" : undefined}
          onClick={() => handleClick("all")}
        >
          All
        </button>
      </li>
      {filterButtons}
    </ul>
  );
}

export default GalleryFilter;
