import React from "react";
import "./About.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";

function About() {
  return (
    <div className="about section">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <figure className="image">
              <img src="/img/logo.png" alt="Ag Plumbing & Electrical" />
            </figure>
          </div>
          <div className="column is-half">
            <h4 className="section-title">About Us</h4>
            <h2>Time is Money</h2>
            <p>
              AG Group was established in January 2009 and has been delivering
              outstanding plumbing services to both domestic and industrial
              customers. The company has grown to be able to operate in fifteen
              (15) teams to provide the necessary equipment and knowledge
              required to deliver quality and cost effective services.
            </p>
            <ul>
              <li>
                <FontAwesomeIcon icon={faCheckSquare} />
                Qualified Teams
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckSquare} />
                Affordable Pricing
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckSquare} />
                Quick Service
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckSquare} />
                10+ Years Experience
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckSquare} />
                100+ Projects Completed
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckSquare} />
                Certified Experts
              </li>
            </ul>
            <p>
              At AG Group we believe that "Time is Money" and take this very
              seriously to ensure we deliver quality service to our clients. We
              pride ourselves in being professional, reliable and competent in
              all aspects of the work we perform. Clients will be informed on
              the progress of the work until completion and beyond.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
