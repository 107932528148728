import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./ContactMap.scss";

const placeUrl =
  "https://www.google.com/maps/place/AG+Group+Plumbing+%26+Electrical+Pty+Ltd/@-26.200249,28.2531733,17z/data=!3m1!4b1!4m5!3m4!1s0x1e951651140fc107:0x5a268904a7e315dc!8m2!3d-26.200249!4d28.255362";

function ContactMap({ center, zoom, markers }) {
  const mapMarkers = markers.map(marker => (
    <Marker
      key={marker.title}
      position={marker.coords}
      title={marker.title}
      onClick={() => window.open(placeUrl)}
    />
  ));

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
    >
      <GoogleMap id="map" center={center} zoom={zoom}>
        {mapMarkers}
        <div className="overlay"></div>
      </GoogleMap>
    </LoadScript>
  );
}

export default ContactMap;
