import React, { useState } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactForm.scss";
import {
  faUser,
  faEnvelope,
  faPhone,
  faPen,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";

function ContactForm() {
  const [submitBtnText, setSubmitBtnText] = useState("Send Message");
  const { register, handleSubmit, errors, reset } = useForm();
  const apiUrl = process.env.REACT_APP_API_URL;
  const formId = process.env.REACT_APP_CONTACT_FORM_ID;

  function onSubmit(data) {
    setSubmitBtnText("Sending...");
    const url = `${apiUrl}contact-form-7/v1/contact-forms/${formId}/feedback`;
    const formData = new FormData();

    formData.append("ag-contact-name", data.name);
    formData.append("ag-contact-email", data.email);
    formData.append("ag-contact-telephone", data.telephone);
    formData.append("ag-contact-subject", data.subject);
    formData.append("ag-contact-message", data.message);

    Axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(res => {
      if (res.data.status === "mail_sent") {
        setSubmitBtnText("Message Sent!");
        reset();
      }
      setTimeout(() => setSubmitBtnText("Send Message"), 2500);
    });
  }

  return (
    <form className="form contact-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="columns is-multiline">
        <div className="column is-half">
          <div className="input">
            <input
              type="text"
              name="name"
              placeholder="Name"
              ref={register({ required: true })}
            />
            <FontAwesomeIcon icon={faUser} />
          </div>
          {errors.name && "First name is required"}
        </div>
        <div className="column is-half">
          <div className="input">
            <input
              type="email"
              name="email"
              placeholder="Email"
              ref={register({ required: true, type: "email" })}
            />
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        </div>
        <div className="column is-half">
          <div className="input">
            <input
              type="tel"
              name="telephone"
              placeholder="Phone Number"
              ref={register()}
            />
            <FontAwesomeIcon icon={faPhone} />
          </div>
        </div>
        <div className="column is-half">
          <div className="input">
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              ref={register()}
            />
            <FontAwesomeIcon icon={faCommentAlt} />
          </div>
        </div>
        <div className="column is-full">
          <div className="input">
            <textarea
              name="message"
              rows="12"
              placeholder="Message"
              ref={register({ required: true, minLength: 5 })}
            />
            <FontAwesomeIcon icon={faPen} />
          </div>
        </div>
        <div className="column is-full">
          <button type="submit" className="button btn-ag">
            {submitBtnText}
          </button>
        </div>
      </div>
    </form>
  );
}

export default ContactForm;
