import React from "react";
import ReactGA from "react-ga";
import "./Masthead.scss";

function Masthead({ refs }) {
  function handleClick() {
    ReactGA.event({
      category: "Button",
      action: 'User clicked "Request Service" button',
    });

    refs.request.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  return (
    <div className="masthead section">
      <div className="container">
        <div className="wrapper">
          <h1>We've Got The Horsepower</h1>
          <p>
            With over 10 years experience and 100+ projects completed, let our
            certified experts assist you with both your plumbing and electrical
            requirements.
          </p>
          <button className="button btn-ag" onClick={handleClick}>
            Request Service
          </button>
        </div>
      </div>
    </div>
  );
}

export default Masthead;
