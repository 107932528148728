import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Obfuscate from "react-obfuscate";
import BookingForm from "./components/BookingForm";
import "./Booking.scss";

function Booking({ refs }) {
  return (
    <div className="booking section" ref={refs.request}>
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <h2>Request Service</h2>
            <hr />
            <p>
              To get the process started, contact our friendly and helpful team
              today. We would be delighted to provide you with a competitive
              quote and inform you more about our services and why our clients
              come back to us time and time again.
            </p>
            <ul className="contact">
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <Obfuscate tel={process.env.REACT_APP_TELEPHONE} />
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                10 Paul Smit Road, Ravenswoods, Boksburg
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <Obfuscate email="info@agplumbing.co.za" />
              </li>
            </ul>
          </div>
          <div className="column is-half">
            <hr style={{ marginTop: 5 }} />
            <p>
              Fill out the form below to request an appointment based upon
              availability.
            </p>
            <BookingForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Booking;
