import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RecentPostContentLoader } from "components/ContentLoader";
import "./RecentPosts.scss";
import moment from "moment";
import { useEffect } from "react";
import Axios from "axios";

function RecentPosts() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    Axios.get(apiUrl + "wp/v2/posts?per_page=3").then(response => {
      setArticles(response.data);
      setLoading(false);
    });
  }, [apiUrl]);

  let posts = articles.map(article => (
    <div className="column is-one-third post" key={article.id}>
      <figure className="image">
        <img src={article.thumbnail} alt={article.title.rendered} />
      </figure>
      <p className="date">{moment(article.date).format("MMM D, YYYY")}</p>
      <Link to={`/blog/${article.slug}`} className="title">
        <h3>{article.title.rendered}</h3>
      </Link>
      <Link to={`/blog/${article.slug}`} className="more-link">
        Read More &gt;
      </Link>
    </div>
  ));

  return (
    <div className="recent-posts section">
      <div className="container">
        <div className="header">
          <div className="wrapper">
            <h4 className="section-title">Stay Informed</h4>
            <h2>Our Latest News</h2>
          </div>
          <Link role="button" className="button btn-ag" to="/blog">
            View Blog
          </Link>
        </div>
        {isLoading ? (
          <RecentPostContentLoader />
        ) : (
          <div className="columns">{posts}</div>
        )}
      </div>
    </div>
  );
}

export default RecentPosts;
