import React from "react";
import "./ServicesList.scss";

function ServicesList() {
  return (
    <div id="services-list" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-one-third">
            <h3>Plumbing</h3>
            <ul>
              <li>Drains</li>
              <li>Geysers</li>
              <li>Heat Pumps</li>
              <li>Manhole Cleaning</li>
              <li>Root Removal</li>
              <li>New Water Lines</li>
              <li>New Installations</li>
              <li>Toilet, Sinks and Basins</li>
              <li>Baths and Taps</li>
              <li>Waterproofing</li>
              <li>Bathroom Renovations</li>
              <li>Jojo Tanks</li>
              <li>Leak Detection</li>
              <li>Drain Camera Inspections</li>
              <li>New Developments</li>
              <li>General Maintenance</li>
            </ul>
          </div>
          <div className="column is-one-third">
            <h3>Electrical</h3>
            <ul>
              <li>DB Boards</li>
              <li>Fault Finding and Maintenance</li>
              <li>Fault Finding and Repairs</li>
              <li>Industrial and Domestic</li>
              <li>Air Conditioning</li>
              <li>New Installations</li>
              <li>Gate Motors</li>
              <li>Garage Motors</li>
              <li>General Electric work</li>
              <li>Maintenance</li>
            </ul>
          </div>
          <div className="column is-one-third">
            <h3>Grease Traps</h3>
            <ul>
              <li>Grease Trap Service</li>
              <li>Oil Separator Service</li>
              <li>Sand Trap Service</li>
              <li>Floor Drain Service</li>
              <li>Clearing Drain Lines</li>
              <li>F.O.G Build Up in Grease Traps</li>
              <li>Eliminating Unpleasant Odours</li>
              <li>Eliminating Bacterial Growth</li>
              <li>Grease Trap Installations</li>
              <li>Oil Separator Installations</li>
              <li>Sand Trap Installations</li>
              <li>Floor Drain Installations</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesList;
