import React from "react";
import FooterInfo from "./components/FooterInfo";
import SubFooter from "./components/SubFooter";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="section">
        <div className="container">
          <FooterInfo />
        </div>
      </div>
      <SubFooter />
    </div>
  );
}

export default Footer;
