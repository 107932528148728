import React from "react";
import Subheader from "./components/Subheader";
import Menu from "./components/Menu";

function Header() {
  return (
    <header>
      <Subheader />
      <Menu />
    </header>
  );
}

export default Header;
