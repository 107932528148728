import React from "react";
import "./AboutHistory.scss";

function AboutHistory() {
  return (
    <div id="about-history" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <h4 className="section-title">Our History</h4>
            <h2>Growth of AG Group</h2>
            <p>
              AG Group Plumbing was founded in 2009 with just a single 1400
              bakkie after founder Andre Greyvensteyn spent a year learning the
              plumbing and electrical trade working at his father’s company in
              Volksrus.
            </p>
            <p>
              After working small jobs for a few years, AG Group Plumbing
              employed it’s first employees in 2012 and has grown every year
              since, adding 2 -3 vehicles to it’s fleet year-on-year. In
              addition, AG Group Plumbing added an electrical division forming
              the company offerings available today.
            </p>
            <p>
              In 2020, AG Group now boasts 15 fleet vehicles and an in-house
              plumbing supply shop. The office team consists of 10 people
              responsible for the smooth running of day to day operations. This
              ensures that clients receive the best plumbing and electrical
              service possible.
            </p>
          </div>
          <div className="column is-half">
            <figure className="image">
              <img src="img/history.jpg" alt="History of AG Plumbing" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutHistory;
