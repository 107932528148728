import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactMap from "./components/ContactMap";
import ContactForm from "./components/ContactForm";
import Obfuscate from "react-obfuscate";
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import "./Contact.scss";

function Contact() {
  const isMobile = window.matchMedia("(max-width: 640px)");

  return (
    <div id="contact">
      <ContactMap
        center={{ lat: -29.91311, lng: 23.147846 }}
        zoom={isMobile.matches ? 5 : 6}
        markers={[
          {
            title: "AG Plumbing & Electrical Pty Ltd Johannesburg",
            coords: { lat: -26.200248, lng: 28.255363 },
          },
          {
            title: "AG Plumbing & Electrical Pty Ltd Western Cape",
            coords: { lat: -33.823317, lng: 18.483858 },
          },
        ]}
      />
      <div className="container section">
        <div className="columns">
          <div className="column is-one-third">
            <h2>Contact Info</h2>
            <hr />
            <p>
              Whether you have a question about our features, service pricing or
              anything else our team is ready to answer all your questions.
            </p>
            <h3>Johannesburg</h3>
            <ul>
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <Obfuscate tel={process.env.REACT_APP_TELEPHONE} />
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                10 Paul Smit Road, Ravenswood, Boksburg
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <Obfuscate email="info@agplumbing.co.za" />
              </li>
            </ul>
            <h3>Western Cape</h3>
            <ul>
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <Obfuscate tel="082 060 0916" />
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                Bloubergstrand, Western Cape
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <Obfuscate email="cape@agplumbing.co.za" />
              </li>
            </ul>
          </div>
          <div className="column is-two-thirds">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
