export default [
  {
    icon: "/img/icons/plumbing.png",
    image: "/img/services/plumbing.jpg",
    title: "Plumbing",
    description:
      "With over 10 years of experience in the plumbing industry, our clients can be guaranteed that a qualified and experienced team will be available to provide the best service and advice possible. We also offer plumbing COC's.",
  },
  {
    icon: "/img/icons/electrical.png",
    image: "/img/services/electrical.jpg",
    title: "Electrical",
    description:
      "AG Plumbing Group recently expanded their offerings to include electrical services. Our qualified teams stand ready to assist with any electrical problems, especially breakdowns and emergencies. Electrical inspections and electrical COC's are also available.",
  },
  {
    icon: "/img/icons/grease-trap.png",
    image: "/img/services/grease-traps.jpg",
    title: "Grease Traps",
    description:
      "We offer grease trap installations for both residential and commercial sectors. Grease trap cleaning and maintenance is also available on a pre-arranged schedule which includes the issuing of a waste disposal certificate.",
  },
  {
    icon: "/img/icons/drain-jetting.png",
    image: "/img/services/drain-jetting.jpg",
    title: "Drain Jetting",
    description:
      "AG Plumbing Plumbing and Electrical have multiple drain jetting systems available to assist with a wide variety of blockages including blocked drains, toilets, showers and baths.",
  },
  {
    icon: "/img/icons/drain-inspections.png",
    image: "/img/services/camera-inspections.jpg",
    title: "Drain Inspections",
    description:
      "On many occasions a blockage will occur as the result of collapsed drain or roots penetrating your drainage system. To prevent a large scale drain replacement, we offer a camera inspection to better pinpoint the problematic sections.",
  },
  {
    icon: "/img/icons/solar.png",
    image: "/img/services/solar-systems.jpeg",
    title: "Solar Inverter Systems",
    description:
      'With the constant threat of loadshedding, the requirement for reliable power has never been higher. AG Plumbing and Electrical offers "off grid" systems for small and large scale projects, with or without solar panels.',
  },
];
