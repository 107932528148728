import React from "react";
import "./About.scss";
import AboutHistory from "./components/AboutHistory";
import AboutQuote from "./components/AboutQuote";
import AboutUs from "./components/AboutUs";
import Clients from "./components/Clients";
import Mission from "./components/Mission";

function About() {
  return (
    <div id="about">
      <div className="header">
        <div className="container">
          <h1>About</h1>
          <p>AG Group Plumbing & Electrical</p>
        </div>
      </div>
      <AboutUs />
      <AboutQuote />
      <AboutHistory />
      <Mission />
      <Clients />
    </div>
  );
}

export default About;
