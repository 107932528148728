import React from "react";
import "./SubFooter.scss";

function SubFooter() {
  let date = new Date();
  let currentYear = date.getFullYear();

  return (
    <div className="subfooter">
      <div className="container">
        <small>&copy; {currentYear} | AG Plumbing and Electrical</small>
        <small>
          Developed with{" "}
          <span role="img" aria-label="love">
            &#128154;
          </span>
          by <a href="http://trademarktechnology.co.za">Trademark Technology</a>
        </small>
      </div>
    </div>
  );
}

export default SubFooter;
