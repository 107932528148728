import React from "react";
import { Link } from "react-router-dom";
import ServiceList from "./components/ServicesList";
import serviceData from "services/api/services";
import "./Services.scss";

function Services() {
  let services = serviceData.map(service => (
    <div className="column is-one-third service" key={service.title}>
      <figure className="image">
        <img src={service.image} alt={service.title} />
      </figure>
      <h3>{service.title}</h3>
      <hr />
      <p>{service.description}</p>
    </div>
  ));

  return (
    <div id="services">
      <div className="header">
        <div className="container">
          <h1>Services</h1>
          <p>What we can offer you.</p>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="columns is-multiline">{services}</div>
        </div>
      </div>
      <ServiceList />
      <div id="services-callout" className="section">
        <div className="container">
          <h1>Continually Striving for Excellence</h1>
          <p>
            We pride ourselves in being professional, reliable and competent in
            all aspects of the work we perform and guarantee the quality of our
            services from start to finish. Just one of the reasons all of our
            clients receive a 30 day workmanship guarantee.
          </p>
          <Link to="/contact" className="button btn-ag">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;
