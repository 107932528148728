import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./WhatsAppButton.scss";

function WhatsAppButton() {
  return (
    <a
      href={`https://wa.me/${
        process.env.REACT_APP_WHATSAPP_NUMBER
      }?text=${encodeURI(process.env.REACT_APP_DEFAULT_WHATSAPP_MESSAGE)}`}
      id="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faWhatsapp} />
    </a>
  );
}

export default WhatsAppButton;
