import React, { createRef } from "react";

import Masthead from "./components/Masthead";
import About from "./components/About";
import Services from "./components/Services";
import Booking from "./components/Booking";
import Projects from "./components/Projects";
import Stats from "./components/Stats";
import RecentPosts from "./components/RecentPosts";

function Home() {
  let refs = {
    request: createRef()
  };

  return (
    <div className="home">
      <Masthead refs={refs} />
      <About />
      <Services />
      <Booking refs={refs} />
      <Projects />
      <Stats />
      <RecentPosts />
    </div>
  );
}

export default Home;
