import React from "react";
import "./Mission.scss";

function Mission() {
  return (
    <div id="about-mission" className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-one-third">
            <h3>Our Vision</h3>
            <hr />
            <p>
              Great prices and excellent services is what we will achieve in
              going forward, to grow with technology and exceed our customer's
              expectations in the service they want and expect.
            </p>
          </div>
          <div className="column is-one-third">
            <h3>Our Mission</h3>
            <hr />
            <p>
              AG Group Plumbing & Electrical will continuously improve the
              delivery of services that exceed all client's expectations and
              will look for opportunities to develop our client relationships.
            </p>
            <p>
              The quality of our work is a reflection of our business and we
              will always strive to better ourselvesas a plumbing, electrical
              and service delivery company.
            </p>
          </div>
          <div className="column is-one-third">
            <h3>Our Core Values</h3>
            <hr />
            <p>
              Our success depends on our client's satisfaction with our work, so
              our courteous service is supported by our quality workmanship by
              meeting our time commitments and our ongoing training and
              development processes.
            </p>
            <p>
              We will act with integrity through adhering to ethical standards
              and always ensure that we deliver value for money.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
